// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-fun-index-js": () => import("/opt/build/repo/src/pages/fun/index.js" /* webpackChunkName: "component---src-pages-fun-index-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-index-js": () => import("/opt/build/repo/src/pages/info/index.js" /* webpackChunkName: "component---src-pages-info-index-js" */),
  "component---src-pages-projects-cadigal-js": () => import("/opt/build/repo/src/pages/projects/cadigal.js" /* webpackChunkName: "component---src-pages-projects-cadigal-js" */),
  "component---src-pages-projects-carazo-js": () => import("/opt/build/repo/src/pages/projects/carazo.js" /* webpackChunkName: "component---src-pages-projects-carazo-js" */),
  "component---src-pages-projects-contemporary-hotels-js": () => import("/opt/build/repo/src/pages/projects/contemporary-hotels.js" /* webpackChunkName: "component---src-pages-projects-contemporary-hotels-js" */),
  "component---src-pages-projects-fab-academy-js": () => import("/opt/build/repo/src/pages/projects/fab-academy.js" /* webpackChunkName: "component---src-pages-projects-fab-academy-js" */),
  "component---src-pages-projects-fab-city-js": () => import("/opt/build/repo/src/pages/projects/fab-city.js" /* webpackChunkName: "component---src-pages-projects-fab-city-js" */),
  "component---src-pages-projects-index-js": () => import("/opt/build/repo/src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-isotype-js": () => import("/opt/build/repo/src/pages/projects/isotype.js" /* webpackChunkName: "component---src-pages-projects-isotype-js" */),
  "component---src-pages-projects-make-here-js": () => import("/opt/build/repo/src/pages/projects/make-here.js" /* webpackChunkName: "component---src-pages-projects-make-here-js" */),
  "component---src-pages-projects-material-driven-design-js": () => import("/opt/build/repo/src/pages/projects/material-driven-design.js" /* webpackChunkName: "component---src-pages-projects-material-driven-design-js" */),
  "component---src-pages-projects-mesh-archive-js": () => import("/opt/build/repo/src/pages/projects/mesh-archive.js" /* webpackChunkName: "component---src-pages-projects-mesh-archive-js" */),
  "component---src-pages-projects-read-write-installation-js": () => import("/opt/build/repo/src/pages/projects/read-write-installation.js" /* webpackChunkName: "component---src-pages-projects-read-write-installation-js" */),
  "component---src-pages-projects-read-write-publication-js": () => import("/opt/build/repo/src/pages/projects/read-write-publication.js" /* webpackChunkName: "component---src-pages-projects-read-write-publication-js" */),
  "component---src-pages-projects-skagen-js": () => import("/opt/build/repo/src/pages/projects/skagen.js" /* webpackChunkName: "component---src-pages-projects-skagen-js" */),
  "component---src-pages-projects-sonar-js": () => import("/opt/build/repo/src/pages/projects/sonar.js" /* webpackChunkName: "component---src-pages-projects-sonar-js" */),
  "component---src-pages-projects-unbound-js": () => import("/opt/build/repo/src/pages/projects/unbound.js" /* webpackChunkName: "component---src-pages-projects-unbound-js" */),
  "component---src-pages-projects-wikifrags-js": () => import("/opt/build/repo/src/pages/projects/wikifrags.js" /* webpackChunkName: "component---src-pages-projects-wikifrags-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

