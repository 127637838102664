export const CONFIGURE_ROUND = 'CONFIGURE_ROUND';
export const STOP_ROUND = 'STOP_ROUND';
export const PLANT_MINES = 'PLANT_MINES';
export const CHANGE_DIFFICULTY = 'CHANGE_DIFFICULTY';
export const SET_BOARD_DIMENSIONS = 'SET_BOARD_DIMENSIONS';
export const WINNER_WINNER_CHICKEN_DINNER = 'WINNER_WINNER_CHICKEN_DINNER';
export const INCREMENT_TIME = 'INCREMENT_TIME';
export const REMOVE_FLAG = 'REMOVE_FLAG';
export const OPEN_CELL = 'OPEN_CELL';
export const ADD_FLAG = 'ADD_FLAG';
export const CLOSE_WINDOW ='CLOSE_WINDOW';

export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';